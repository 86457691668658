<template>
    <div class="ai-camera">
        <van-notice-bar class="notice" v-if="eventInfo" color="#3d6561" background="#cccccc" :text="eventInfo" mode="closeable"/>
        <router-view/>
        <Nav />
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
const mqtt = require('mqtt');
import MQTT_OPTIONS from '@/config/mqtt.js'
export default {
    components:{
        Nav:Nav
    },
    data(){
        return{
            client:null,
            timer:null,
            eventInfo:''
        }
    },
    provide() {
        return {
            client: ()=> this.client
        }
    },
    mounted(){
        const projectCode = window.sessionStorage.getItem('projectCode');
        this.topicPic = `/sudp/sync/aicamera/${projectCode}/+/image/get`
        //this.initMqtt();
        // this.$nextTick(()=>{
        //     this.subscribePic();
        // })
    },
    beforeDestroy(){
        // this.unsubscribePic();
        // this.client ? this.client.end() : void 0;
    },
    methods:{
        initMqtt(){
            this.client  = mqtt.connect('ws://ahdcloud.com:20502/mqtt',MQTT_OPTIONS);
            this.client.on('connect', (e) => {
                console.log(e, "MQTT连接成功！！！");
            })
            // 接收消息处理
            this.client.on('message', (topic, message) => {
                const data = JSON.parse(message.toString());
                console.log(`全局-收到来着${topic}的信息`, data);
                if (topic.includes('/image/get')) {
                    this.handleMsg(data);
                }
            })
            // 断开发起重连
            this.client.on('reconnect', (error) => {
                console.log('正在重连:', error)
            })
            // 链接异常处理
            this.client.on('error', (error) => {
                console.log('连接失败:', error)
            })
        },
        handleMsg(msg){
            this.eventInfo = `触发【围界闯入】事件,位置：${msg.location},时间:${msg.uploadTime}`;

            this.timer = setTimeout(()=>{
                this.eventInfo = '';
            },10000)
        },
        subscribePic(){
            this.client.subscribe(this.topicPic, { qos: 1 }, (err) => {
                if (!err) {
                console.log("订阅成功!", this.topicPic);
                } else {
                console.log("订阅失败!", this.topicPic);
                }
            });
        },
        unsubscribePic(){
            this.client.unsubscribe(this.topicPic, { qos: 1 }, (err) => {
                if (!err) {
                console.log("取消订阅成功!", this.topicPic);
                }
            })
        }
    },
}
</script>

<style lang="scss">
.van-button--primary {
    color: #fff;
    background-color: #57c3c2;
    border: 1px solid #57c3c2;
}
.glo-content{padding: 4%;}
.glo-magbot{margin-bottom: 24%;}
.glo-relative{
    position: relative;
}
.notice{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 2;
    box-sizing: border-box;
}
</style>
